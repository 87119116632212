import React from "react";
import "../../../../Assets/css/staticPage.css";
import { Col, Container, Row } from "react-bootstrap";
const CertifiedDiamonds = () => {
  return (
    <div className="CertifiedDiamonds_page">
      <div className="CertifiedDiamonds-banner">
        <img
          className="img-fluid"
          alt="banner"
          src={require("../../../../Assets/images/custom-design/certified-diamonds/banner.png")}
        />
        <div className="text-center CertifiedDiamonds-banner-txt">
          <h3>Find Your Certified Diamond</h3>
          <p>Why Choose a Certified Diamond?</p>
        </div>
      </div>

      <div className="CertifiedDiamonds-2">
        <Container>
          <img
            className="img-fluid mb-4"
            alt="banner"
            src={require("../../../../Assets/images/custom-design/certified-diamonds/Diamantes-naturales.png")}
          />
        </Container>
        <div className="CertifiedDiamonds-box">
          <Container>
            <h3>
              Find the Perfect Diamond with Guaranteed <br />
              Certification and Exclusivity at Spinosa
            </h3>
            <p>
              We only work with natural diamonds certified by GIA, ensuring
              quality, purity, and transparency in every piece.
            </p>
          </Container>
        </div>
      </div>

      <div className="CertifiedDiamonds-3">
        <Container>
          <Row className="align-items-center">
            <Col md={6} xs={12}>
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/1.png")}
              />
            </Col>
            <Col md={6} xs={12}>
              <h4>Why Choose a Certified Diamond</h4>
              <p>
                In the world of high jewelry, the difference between an
                exceptional diamond and a common one lies in certification.
              </p>
              <p>
                A certified diamond is more than a gemstone—it is a guarantee of
                quality, authenticity, and long-term value.
              </p>
              <p>
                At Spinosa Diamonds, we work exclusively with natural diamonds
                certified by the Gemological Institute of America (GIA), the
                most prestigious authority in the industry.
              </p>
              <p>
                While other laboratories may be more flexible in their criteria,
                GIA offers the most rigorous evaluation of the 4Cs (Cut, Color,
                Clarity, and Carat Weight).
              </p>
              <p>
                When you choose a diamond certified by Spinosa Diamonds, you
                choose:
              </p>
              <ul>
                <li>✔ Perfection – Only the highest-graded diamonds</li>
                <li>✔ Security – GIA certification and conflict-free</li>
                <li>
                  ✔ Exclusivity – Premium selection with personalized advice{" "}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="CertifiedDiamonds-banner">
        <Container fluid className="d-flex justify-content-center">
          <div className="position-relative">
            <img
              className="img-fluid"
              alt="banner"
              src={require("../../../../Assets/images/custom-design/certified-diamonds/2.png")}
            />
            <div className="text-center CertifiedDiamonds-banner-txt">
              <h3>
                Want a unique jewel? Talk to me, Manuel Spinosa, <br />
                and let’s design your exclusive piece.
              </h3>
            </div>
          </div>
        </Container>
      </div>

      <div className="CertifiedDiamonds-3 text-center">
        <h3>The 4Cs of a Diamond: Discover the Key to Its Beauty and Value </h3>
        <p className="pb-0 mb-0">
          The value and beauty of a diamond are defined by the 4Cs according to
          GIA: Cut, Color, Clarity, and Carat Weight.{" "}
        </p>
      </div>

      <div className="CertifiedDiamonds-4">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={6} xs={12}>
              <img
                className="img-fluid pr-md-5"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/3.png")}
              />
            </Col>
            <Col md={6} xs={12}>
              <h3>Cut: The Art of Brilliance</h3>
              <p>
                The cut of a diamond is the most important factor in its
                sparkle. A perfect cut maximizes how light reflects within the
                diamond, creating its iconic brilliance.
              </p>
              <ul>
                <li>
                  ✔ Cut grades: Excellent, Very Good, Good, Fair, and Poor
                </li>
                <li>
                  ✔ The best cuts (GIA Excellent or Very Good) guarantee maximum
                  fire and brilliance
                </li>
                <li>
                  ✔ The round brilliant cut is the most popular for its
                  unmatched luminosity
                </li>
              </ul>

              {/* <p>How to choose the best diamond cut?</p> */}
              {/* <p>
                Click the button on the right side of this page and talk
                directly with me.
              </p> */}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="CertifiedDiamonds-5 text-center">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={12} xs={12}>
              <h3>Color: The Purity of Light</h3>
              <p>
                The most valuable diamonds are colorless, allowing light to pass
                through without interference. The GIA grades them on a scale
                from D (colorless) to Z (light yellow/brown).
              </p>
              <ul>
                <li>
                  ✔ For diamonds under 0.50 ct, quality and purity will always
                  be in the G-H / VVS1-VS2 range, and they include a certificate
                  from Manuel Spinosa Jewellery.
                </li>
                <li>
                  ✔ For diamonds over 0.50 ct, a GIA certificate is provided,
                  guaranteeing quality and authenticity.
                </li>
                <li>
                  ✔ The less color a diamond has, the more exclusive and
                  brilliant it will be.
                </li>
              </ul>

              <img
                className="img-fluid p-5 pb-0"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/4.png")}
              />

              <h3>Clarity: Natural Perfection</h3>
              <p>
                Every diamond has internal characteristics called inclusions.
                The less visible they are, the greater the diamond’s purity and
                value.
              </p>
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/5.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="CertifiedDiamonds-6">
        <Container>
          <h3>Carat Weight: The Weight of Exclusivity </h3>
          <p>
            The carat (ct) measures a diamond's weight and is one of the key
            factors in its value. One carat equals 0.2 grams, and its rarity and
            exclusivity increase with size.{" "}
          </p>
          <p>
            Larger diamonds are harder to find in nature, making them even more
            precious. That’s why every stone at Spinosa Diamonds is carefully
            selected to ensure the best combination of size, quality, and
            brilliance.
          </p>
        </Container>
      </div>

      <div className="CertifiedDiamonds-7">
        <Container>
          <h3 className="text-center mb-5">An Exclusive, Fast, and Intermediary-Free Purchase Process </h3>
          <Row className="justify-content-center align-items-center">
            <Col md={6} xs={12}>
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/6.png")}
              />
            </Col>
            <Col md={6} xs={12}>
              <h3>The Ring You Want, Designed with You and Ready in Days </h3>
              <p>
                In many jewelry stores, buying a ring means choosing from a
                catalog—a piece produced in series and owned by others too.
              </p>
              <p>
                And if you decide to customize it, the wait can extend for weeks
                or even months, as many shops are mere intermediaries relying on
                third-party production.
              </p>
              <h4>At Spinosa Diamonds, the process is completely different</h4>
              <p>
                We design and craft each ring in our own workshop, with a team
                of highly skilled artisans. We don’t outsource production; we
                create everything here in Marbella.
              </p>
              <p>
                The result? A one-of-a-kind ring, made for you and ready in just
                a few days.
              </p>
              <ul>
                <li>
                  ✔ Custom-designed: You choose the diamond, the style, and
                  every detail with Manuel Spinosa
                </li>
                <li>
                  ✔ Crafted in our own workshop: No intermediaries, no
                  unnecessary delays
                </li>
                <li>
                  ✔ Ready in days, not months: Because we control the entire
                  process, from design to final polish
                </li>
                <li>
                  ✔ Full transparency: You’ll be informed of your ring’s status
                  throughout the process
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="CertifiedDiamonds-8">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={6} xs={12}>
              <h3 className="mb-3">
                Buy Your Ring or Exclusive Jewel with the Support of Manuel
                Spinosa, Wherever You Are
              </h3>
              <p>
                Are you on vacation in Marbella? Take your exclusive ring or
                jewel with you before returning home
              </p>
              <p>
                If you're visiting Marbella, you can book an appointment with
                Manuel Spinosa. We'll design your ring together and manufacture
                it while you enjoy your holidays. When it's time to leave,
                you'll take with you a unique jewel made just for you.
              </p>
            </Col>
            <Col md={6} xs={12}>
              <img
                className="img-fluid pr-md-5"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/7.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="CertifiedDiamonds-9">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <img
                className="img-fluid mb-3"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/8.png")}
              />
              <h3>Can’t visit in person to design your jewel?</h3>
              <p>
                No problem. We schedule a video call, you select your diamond,
                and we define every detail of the design. When you arrive in
                Marbella, your jewel will be ready.
              </p>
            </Col>
            <Col md={6} xs={12}>
              <img
                className="img-fluid mb-3"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/certified-diamonds/9.png")}
              />
              <h3>Live far away and don’t plan to visit?</h3>
              <p>
                That's also possible. We talk to you as if you were right here
                in our boutique, show you design options, materials, and
                qualities. We guide you through every step and ship your ring or
                jewel to wherever you are, ensuring the same exclusive
                experience as if you were in Marbella.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CertifiedDiamonds;
