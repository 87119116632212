import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const JewelryInsurance = () => {
  return (
    <div className="JewelryInsurance_page">
      <div className="JewelryInsurance-banner">
        <img
          className="img-fluid"
          alt="banner"
          src={require("../../../../Assets/images/custom-design/jewelry-insurance/banner.png")}
        />
        <div className="text-center JewelryInsurance-banner-txt">
          <h3>Insure Your Jewelry with Complete Peace of Mind</h3>
          <p>Each Spinosa piece is both an emotional and financial investment.
            That's why we offer the option to insure your jewelry, <br/> so you can
            wear and travel with it worry-free. We explain how to do it easily,
            with a specialized provider.</p>
        </div>
      </div>

      <div className="JewelryInsurance-1">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <img
                className="img-fluid mb-4"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/jewelry-insurance/1.png")}
              />
            </Col>
            <Col xs={12} md={6}>
              <h3>Insure Your Jewelry with the Help of Spinosa Diamonds</h3>
              <h4>So You Can Enjoy It Always, Worry-Free</h4>
              <p>
                By now, you probably know: a Spinosa jewel is not just an emotional choice — it's an investment.
              </p>
              <p>
                And like any valuable investment, it deserves to be protected.
              </p>
              <p>
                That's why at Spinosa Diamonds, we offer you the possibility to
                insure your custom jewelry with specific coverage designed
                especially for fine jewelry —crafted with your peace of mind in
                mind, and with everything that piece represents.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="JewelryInsurance-2">
        <Container>
          <h4>A Policy Worthy of Your Jewel</h4>
          <p>
            We work with Técnica Aseguradora Internacional F&A, S.L.,
            specialists in jewelry insurance and officially authorized as a
            Coverholder at Lloyd's, the largest and most prestigious insurance
            market in the world
          </p>
        </Container>
      </div>

      <div className="JewelryInsurance-1">
        <Container>
          <Row className="align-items-center">
           
            <Col xs={12} md={6}>
              <h4>Why does this matter to you?</h4>
              <p>Because even though the policy is managed locally, it's backed by an internationally recognized entity.</p>
              <p>You're not buying generic insurance —you're protecting your one-of-a-kind jewel: engagement rings, wedding bands, necklaces, earrings, bracelets… pieces that tell a story.</p>
            </Col>
            <Col xs={12} md={6}>
              <img
                className="img-fluid mb-4"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/jewelry-insurance/2.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="JewelryInsurance-1 pt-0">
        <Container>
          <Row className="align-items-center">
          <Col xs={12} md={6}>
              <img
                className="img-fluid mb-4"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/jewelry-insurance/3.png")}
              />
            </Col>
            <Col xs={12} md={6}>
              <h4>What Does This Insurance Cover?</h4>
              <ul>
                <li>Theft or burglary: whether you're at home or traveling</li>
                <li>Accidental loss</li>
                <li>Unexpected damage: breaks, impacts, wear and tear</li>
              </ul>
              <p>Everything that could happen… is covered.</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="JewelryInsurance-4">
        <Container>
          <h4>How Do You Get It? Very Simple</h4>
          <ul>
            <li><span>1</span> We design your jewel together</li>
            <li><span>2</span> We appraise it and prepare the documentation</li>
            <li><span>3</span> We present you the insurance proposal</li>
            <li><span>4</span> We activate the policy… and you only have to enjoy wearing it</li>
          </ul>
          <p>And if you already own a Spinosa jewel, we can still help you insure it. Just write to us</p>
        </Container>
      </div>

      <div className="JewelryInsurance-1">
        <Container>
          <Row className="align-items-center">
          <Col xs={12} md={6}>
              <img
                className="img-fluid m-auto d-table"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/jewelry-insurance/4.png")}
              />
            </Col>
            <Col xs={12} md={6}>
              <h4>What Does This Insurance Cover?</h4>
              <p>You don't need to search for options on your own or compare policies
              that might not cover what really matters.</p>
              <p>At Spinosa, we advise you, guide you, and make it easy. Because we know
              a jewel isn't just valuable —it's emotional.</p>
              <p>And protecting it is part of the luxury you deserve.</p>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
};

export default JewelryInsurance;
