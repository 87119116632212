import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../../Assets/css/staticPage.css";
const CustoMDesign = () => {
  return (
    <div className="Custom_Designs_page">
      <div className="custom-design-banner">
        <img
          className="img-fluid"
          alt="banner"
          src={require("../../../Assets/images/custom-design/banner.png")}
        />
        <h3>
          Design Your Custom Jewelry <br />
          with Spinosa Diamonds
        </h3>
      </div>
      <div className="custom-design-1">
        <Container>
          <h3>Visualize Your Jewel Before It Exists. 3D CAD Design</h3>
          <p>
            Imagine seeing your dream jewel on screen, with all its details and
            finishes, before it even exists. At Spinosa Diamonds, we make it
            possible thanks to our 3D CAD technology, which allows you to
            visualize how your ring, bracelet or earrings will look — as if they
            were already in your hands.
          </p>
          <p>
            Proportion, brilliance, shape... everything comes to life before
            your eyes. This is how we envision the future: with precision,
            beauty, and emotion.
          </p>
        </Container>
      </div>
      <div className="custom-design-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5}>
              <img
                className="img-fluid pr-md-5"
                alt="banner"
                src={require("../../../Assets/images/custom-design/personal.png")}
              />
            </Col>
            <Col md={6}>
              <h4>
                We Design Together, with the Personal Guidance of Manuel
                Espinosa
              </h4>
              <p>
                Unlike catalog jewelry — which may be valuable but ends up being
                one among many — here we design with you, either from scratch or
                based on one of our exclusive models.
              </p>
              <p>
                And if you don’t know where to start, don’t worry — I’ll guide
                you.  I’m Manuel Espinosa, with years of experience designing
                engagement rings, wedding bands, and fine jewelry.
              </p>
              <p>
                I'll be with you every step of the way to ensure the result is
                just as perfect as you imagined.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col md={6}>
              <h4>Unique Pieces Crafted in Our Marbella Workshop</h4>
              <p>
                You can also choose from our exclusive collections — unique
                designs crafted in our Marbella workshop.
              </p>
              <p>
                Limited-edition pieces with a distinctive identity you’ll only
                find here.  If you’re looking for something special but already
                defined, our collections are made for you — soulful jewels that
                blend style, elegance, and individuality.
              </p>
            </Col>
            <Col md={5} className="order-first order-md-last">
              <img
                className="img-fluid pl-md-5"
                alt="banner"
                src={require("../../../Assets/images/custom-design/workshop.png")}
              />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col md={5}>
              <img
                className="img-fluid pr-md-5"
                alt="banner"
                src={require("../../../Assets/images/custom-design/quality.png")}
              />
            </Col>
            <Col md={6}>
              <h4>Unbeatable Quality and Competitive Prices </h4>
              <p>Now let’s talk about price</p>
              <p>
                As a Certified Diamond Specialist Center, we constantly compare
                quality and pricing across the market.
              </p>
              <p>
                That’s why we can confidently say we offer the most competitive
                prices in the industry.
              </p>
              <p>
                And not only that — our quality is often simply incomparable.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col md={6}>
              <h4>An Investment That Shines Over Time</h4>
              <p>
                A Spinosa jewel is not only an emotional choice — it’s a smart
                investment.
              </p>
              <p>
                
                Acquire pieces that increase in value from the very first
                moment.
              </p>
              <p>
                
                Buying a jewel like this makes you a brilliant decision-maker.
              </p>
              <p>
                
                And yes, you’ll also be able to say with pride: <br />
                <b>“I designed this jewel, just for you.”</b>
              </p>
            </Col>
            <Col md={5} className="order-first order-md-last">
              <img
                className="img-fluid pl-md-5"
                alt="banner"
                src={require("../../../Assets/images/custom-design/investment.png")}
              />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col md={5}>
              <img
                className="img-fluid pr-md-5"
                alt="banner"
                src={require("../../../Assets/images/custom-design/insure.png")}
              />
            </Col>
            <Col md={6}>
              <h4>Want to Insure Your Jewelry? We’ll Help You</h4>
              <p>
                Following our philosophy of offering complete and personalized
                service, we also provide you with the option to insure your
                jewel — with our expert guidance.
              </p>
              <p>
                
                Travel with peace of mind, wear your jewel confidently, and
                forget about hiding it out of fear.
              </p>
              <p>
                
                Travel with peace of mind, wear your jewel confidently, and
                forget about hiding it out of fear.
              </p>
              <p>
                
                <a href="/contact" className="text-dark">Click here for more information</a>, ask us directly in the
                chat, or bring it up during your appointment.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="custom-design-1 bg-white pt-0">
        <img
          className="img-fluid"
          alt="banner"
          src={require("../../../Assets/images/custom-design/client-img.png")}
        />
        <div className="text-center mt-5 text-black">
          <h3>Shall We Begin? Let’s Design Your Next Jewel Together</h3>
          <p>
          <a href="/contact" className="text-dark">Click on the chat you see on your screen and talk directly with me,
            Manuel.</a>
          </p>
          <p>
            
            We can arrange an appointment to meet online or in person at our
            Marbella center.
          </p>
          <p>
            
            And remember, since we are both designers and manufacturers, you’ll
            receive your jewel faster than anywhere else — and at the best
            price.
          </p>
          <p>
            
            In just a few days, your jewel will be ready to shine and move
            hearts like no other.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustoMDesign;
