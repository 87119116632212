import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Heading from "../ui/Heading";
import Slider from "react-slick";
import { Link } from "react-router-dom"
import LegacyOne from '../Assets/img/legacy_one.webp'
import LegacyTwo from '../Assets/img/legacy_two.webp'
import LegacyThree from '../Assets/img/legacy_three.webp'
export default function OurLeagcy() {


    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };


    return (
        <section className="section-space">
            <Container fluid className="home-showcase-container our_legacy_section">
                <Heading>
                    <h2> Personalize your jewelry: craftsmanship and exclusive design  </h2>
                    <p>  Trusted Craftsmanship Since 1958 </p>
                </Heading>
                <div className="legacy_container">
                    <Slider {...settings}>
                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/certified-diamonds.jpg')}  loading="lazy" alt="Legacy one " />
                            </div>
                            <div className="text-box">
                                <h2>Certified Diamonds </h2>
                                <p>Discover the only place where we explain the diamonds we work with, what a certified diamond is, the GIA certification, and how we can help you.</p>
                                <Link to="/certified-diamonds"> Learn More </Link>
                            </div>
                        </div>

                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/jewelry-insurance.jpg')}  loading="lazy" alt="Legacy one " />
                            </div>
                            <div className="text-box">
                                <h2> Jewelry Insurance  </h2>
                                <p> Each Spinosa piece is both an emotional and financial investment. That’s why we offer the option to insure your jewelry, so you can wear and travel with it worry-free. We explain how to do it easily, with a specialized provider.</p>
                                <Link to="/jewelry-insurance"> Learn More </Link>
                            </div>
                        </div>

                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/event-proposal.jpg')}  loading="lazy" alt="Legacy one  " />
                            </div>
                            <div className="text-box">
                                <h2> Events Proposal </h2>
                                <p>Have the ring? Now let's plan the proposal. Diana Spinosa will help you create a magical, unforgettable moment tailored to your story. From an intimate dinner to a helicopter ride—everything is possible when you're in the right hands.</p>
                                <Link to="/events-proposal"> Learn More </Link>
                            </div>
                        </div>
                    </Slider>
                </div>

            </Container>
        </section>
    )
}
